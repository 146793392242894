import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Seo from "../components/Seo"

const Tone = () => {
  return (
    <div id="tone" className="-sections">
      <main className="m-main">
        <div className="_wr">
          <div className="_w">
            <div className="m-main__content _12">
              <Seo title="Tone of voice" />
              <h1>Tone of voice</h1>
              <p>
                All communication should be friendly, positive and transparent,
                but still professional. The goal is to listen to the audience
                and provide value with everything the company puts out.
              </p>
              <p>
                Create empowering content. Educate the community. Help them grow
                with us. Provide useful feedback to clients, employees and the
                community alike.
              </p>
              <StaticImage
                className="a-image -voice"
                src="../images/voice.png"
                alt="voice"
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Tone
