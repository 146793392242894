import React from "react"
import Seo from "../components/Seo"

const FONT_CONTENT = [
  {
    title: "Gilroy",
    subHeading: "Gilroy is Ewasoft's primary typeface.",
    firstText:
      "Gilroy is a modern sans serif with a geometric touch. An older brother of the original Qanelas font family. It comes in 20 weights, 10 uprights and its matching italics.",
    secondText:
      "Ewasoft uses Gilroy in all its internal and external documentation, social media and and PR content.",
  },
  {
    title: "Roboto",
    subHeading: "Roboto is Ewasoft’s secondary typeface.",
    firstText:
      "Roboto has a mechanical skeleton and the forms are largely geometric.",
    secondText:
      "While some grotesks distort their letterforms to force a rigid rhythm, Roboto doesn’t compromise, allowing letters to be settled into their natural width.",
  },
  {
    title: "Montserrat",
    subHeading: "Montserrat is Ewasoft’s secondary typeface.",
    firstText:
      "The old posters and signs in the traditional Montserrat neighborhood of Buenos Aires inspired Julieta Ulanovsky to design this typeface and rescue the beauty of urban typography that emerged in the first half of the twentieth century.",
    secondText:
      "Ewasoft uses Montserrat primaraly in its documentation and presentations as a header font.",
  },
  {
    title: "Open Sans",
    subHeading: "Open Sans is an approved alternative typeface.",
    firstText:
      "In the case that the primary and secondary typeface cannot be used (i.e. the employees who aren’t members of the design team may not have these fonts installed at all times) an alternative is made available.",
    secondText:
      "Open Sans can be used in emails, quick draft documenation, GSuite files etc.",
    thirdText:
      "Other Google Fonts are acceptable when it comes to company presentations.",
  },
]

const Typography = () => {
  return (
    <div id="typography" className="-sections">
      <main className="m-main">
        <div className="_wr">
          <div className="_w">
            <div className="m-main__content _12">
              <Seo title="Typography" />
              <h1>Typography</h1>
              <p>
                The typeface a company uses is a direct reflection of who they
                are. It speaks to taste and resolve, and is the very essense of
                its brand.
              </p>
              <div className="_wr-f">
                <div className="_w -typography">
                  {FONT_CONTENT.map((content, key) => {
                    const {
                      title,
                      subHeading,
                      firstText,
                      secondText,
                      thirdText,
                    } = content
                    return (
                      <div className="m-main__content--column _l6" key={key}>
                        <h3 className="m-main__content--columnTitle">
                          {title}
                        </h3>
                        <h4 className="m-main__content--columnSubHeading">
                          {subHeading}
                        </h4>
                        <p className="m-main__content--text">{firstText}</p>
                        <p className="m-main__content--text">{secondText}</p>
                        <p className="m-main__content--text">{thirdText}</p>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="m-typography a-separator">
                <div className="m-typography__content">
                  <h2 className="a-heading -large">Example of type use</h2>
                  <p className="m-typography__content--text">
                    Ewa has no rules to speak of when it comes to header or body
                    text size or spacing.
                  </p>
                  <p className="m-typography__content--text">
                    We encourage you to go by heart and feeling while keeping up
                    with the best practices regarding typography usage. Feel
                    free to try out new trends, but follow the brands classic,
                    minimalist style.
                  </p>
                </div>
                <div className="m-typography__about _wr">
                  <div className="_w">
                    <div className="_l6 m-typography__about--column">
                      <span className="a-caption -typography">About us</span>
                      <h2 className="m-typography__about--heading">
                        We are your one stop digital partner
                      </h2>
                    </div>
                    <div className="_l6 m-typography__about--column">
                      <p className="m-typography__about--text">
                        We help you to jointly create and implement your digital
                        vision. What is more, ewasoft is your reliable partner
                        for any custom software, eCommerce solution or simply to
                        meet a deadline on short notice. In a nutshell, best
                        practices from leading companies and visionaries across
                        the globe are provided by us.
                      </p>
                      <p className="m-typography__about--text -small">
                        Ewasoft helps companies to reach the next level and to
                        connect with their customers and employees on the
                        digital front. New technological solutions offer the
                        possibility to create value and streamline processes,
                        whilst enhancing the customer retention rate.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Typography
