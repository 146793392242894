import React, { useEffect, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import BackImage from "./BackImage"

const Modal = () => {
  const [closeModal, setCloseModal] = useState(true)

  useEffect(() => {
    let body
    if (typeof document !== `undefined`) {
      body = document.body
    }
    closeModal
      ? (body.style.overflowY = "hidden")
      : body.style.removeProperty("overflow-y")
  }, [closeModal])

  return (
    <div
      className={`m-modal ${closeModal ? "" : "-hidden"}`}
      role="dialog"
      aria-label="Ewasoft intro modal"
    >
      <BackImage className="a-image -global" />
      <div className="m-modal__content">
        <StaticImage
          layout="constrained"
          src="../images/ewasoft-logo.png"
          alt="ewasoft logo"
          placeholder="tracedSVG"
        />
        <h2 className="m-modal__content--heading">Styleguide</h2>
        <button
          className="a-button -main -mt15 a-hover -effectOne"
          onClick={() => {
            setCloseModal(false)
          }}
        >
          Start
        </button>
      </div>
    </div>
  )
}

export default Modal
