import React from "react"
import Icon from "./Icon"

const Card = ({ card }) => {
  const { title, text, iconId } = card

  return (
    <div className="m-card -mt50">
      <div className="_wr">
        <div className="_w">
          <div className="_xxl3">
            <div className="m-card__box">
              <Icon id={iconId} />
            </div>
          </div>
          <div className="_xxl9">
            <div className="m-card__content">
              <h3>{title}</h3>
              <p>{text}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Card
