import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import React from "react"
import Seo from "../components/Seo"

const querySocialCards = graphql`
  {
    allSocialsSectionJson {
      edges {
        node {
          social_cards {
            id
            alt
            image_path {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`

const CommunicationSection = () => {
  const socialCardsData = useStaticQuery(querySocialCards)
  const path = socialCardsData.allSocialsSectionJson.edges[0]
  const socialCards = path.node.social_cards

  return (
    <div id="communication" className="-sections">
      <main className="m-main">
        <div className="_wr">
          <div className="_w">
            <div className="m-main__content _12">
              <Seo title="Communication" />
              <h1>Socials</h1>
              <p>
                Ewa’s socials should bring value to the community. The posts
                should educate our followers in any number of fields our people
                have expertise in.
              </p>
              <p>
                Ewasoft is here to support, answer questions, and keep the
                community updated on our latest achievements.
              </p>
              <section className="m-section -socialCards -gray -mt50 _wr">
                <div className="m-section__content _w">
                  {socialCards.map(card => {
                    const { id, alt, image_path } = card
                    const image = getImage(image_path)

                    return (
                      <div
                        className="_xl4 _s6 m-section__content--column"
                        key={id}
                      >
                        <GatsbyImage image={image} alt={alt} />
                      </div>
                    )
                  })}
                </div>
              </section>
              <section className="m-section a-separator">
                <div className="m-section__content">
                  <h2 className="a-heading -large">Presentations</h2>
                  <p className="m-section__content--text">
                    Ewasoft’s presentations are simple and clean. They let the
                    information speak for itself. Make sure to respect Ewa’s
                    chosen typeface and colors.
                  </p>
                  <p className="m-section__content--text">
                    Make sure to use up to two typefaces per presentation, one
                    for headers and another for body text. A white background is
                    a must (except on the title slide), with Ewa’s Black and Red
                    used in text and graphic elements.
                  </p>
                  <p className="m-section__content--text">
                    Any and all graphic elements are allowed in presentations,
                    however, make sure to stick to one style in order to avoid
                    making the presentation look too busy and mismatched.
                  </p>
                  <StaticImage
                    src="../images/presentations.png"
                    layout="constrained"
                    alt="presentation"
                  />
                </div>
              </section>
              <section className="m-section a-separator">
                <div className="m-section__content">
                  <h2 className="a-heading -large">Print materials</h2>
                  <p className="m-section__content--text">
                    Ewasoft has a number of print materials to be used for
                    promotion and employee use. These include business cards,
                    notebooks, cups, shirts, stationary, envelopes, folders etc.
                  </p>
                </div>
              </section>
              <section className="m-layout -print _wr">
                <div className="_w">
                  <div className="_xl6 m-layout__column no-gutters">
                    <div className="_xl12 m-layout__column--subColumn -big -top">
                      <StaticImage
                        src="../images/ewa-mockup_full-stationary-perspective.jpg"
                        layout="constrained"
                        objectFit="cover"
                        alt="laptop full stationary perspective"
                      />
                    </div>
                    <div className="_w -subColWrapper">
                      <div className="_xl4 m-layout__column--subColumn">
                        <StaticImage
                          src="../images/ewa-mockup_notebook.jpg"
                          layout="constrained"
                          objectFit="cover"
                          alt="ewa notebook"
                        />
                      </div>
                      <div className="_xl4 m-layout__column--subColumn">
                        <StaticImage
                          src="../images/ewa-mockup_paper-bag.jpg"
                          layout="constrained"
                          objectFit="cover"
                          alt="ewa paper bag"
                        />
                      </div>
                      <div className="_xl4 m-layout__column--subColumn">
                        <StaticImage
                          src="../images/ewa-mockup_polo-shirt-black.jpg"
                          layout="constrained"
                          objectFit="cover"
                          alt="ewa polo shirt black"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="_xl6 m-layout__column no-gutters">
                    <div className="_w -subColWrapper">
                      <div className="_xl4 m-layout__column--subColumn">
                        <StaticImage
                          src="../images/ewa-mockup_notebook,mug-and-pen.jpg"
                          layout="constrained"
                          objectFit="cover"
                          alt="ewa notebook mug and pen"
                        />
                      </div>
                      <div className="_xl4 m-layout__column--subColumn">
                        <StaticImage
                          src="../images/ewa-mockup_A4-envelope.jpg"
                          layout="constrained"
                          objectFit="cover"
                          alt="ewa a4 envelope"
                        />
                      </div>
                      <div className="_xl4 m-layout__column--subColumn">
                        <StaticImage
                          src="../images/ewa-mockup_polo-shirt-white.jpg"
                          layout="constrained"
                          objectFit="cover"
                          alt="ewa polo shirt white"
                        />
                      </div>
                    </div>
                    <div className="_xl12 m-layout__column--subColumn -big -bottom">
                      <StaticImage
                        src="../images/ewa-mockup_business-card.jpg"
                        layout="constrained"
                        objectFit="cover"
                        alt="ewa business card"
                      />
                    </div>
                  </div>
                </div>
              </section>
              <section className="m-section a-separator">
                <div className="m-section__content">
                  <h2 className="a-heading -large">Documents</h2>
                  <p className="m-section__content--text">
                    Ewa has set templates for all offers, contracts,
                    questionnaires and such.
                  </p>
                  <p className="m-section__content--text">
                    There are two types of templates, the vertical and
                    horisontal. The vertical has a light and dark version, where
                    the dark version is favored in most documentation. The
                    horisontal only has a light version.
                  </p>
                  <p className="m-section__content--text">
                    The documentation uses Ewa Red sparingly, and Ewa Black in
                    the documentation sent via the web. This is substituted by
                    Rich Black in print. The fonts used are the approved ones
                    mentioned in the Typography section.
                  </p>
                </div>
              </section>
              <section className="m-section -documents -gray -mt30 _wr">
                <div className="m-section__content _w">
                  <div className="_xl3 m-section__content--column">
                    <StaticImage
                      src="../images/e-commerce-questionnaire.png"
                      layout="constrained"
                      objectFit="cover"
                      alt="e commerce questionnaire"
                      className="m-section__content--columnImage"
                    />
                  </div>
                  <div className="_xl3 m-section__content--column">
                    <StaticImage
                      src="../images/questionaire.png"
                      layout="constrained"
                      objectFit="cover"
                      alt="questionairer"
                      className="m-section__content--columnImage"
                    />
                  </div>
                  <div className="_xl6 m-section__content--column">
                    <StaticImage
                      src="../images/website-offer.png"
                      layout="constrained"
                      objectFit="cover"
                      alt="website offer"
                      className="m-section__content--columnImage"
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default CommunicationSection
