import React from "react"
import Card from "./Card"

const Intro = ({ cards }) => {
  return (
    <div className="m-main__content _12" id="intro">
      <div className="_wr">
        <div className="_w">
          <div className="-sections">
            <h1>Intro</h1>
            <h3 className="-mt30">About ewa</h3>
            <p>
              Core business of ewasoft is the development of complex web
              applications based on standardized processes and best practices,
              where each team member has the opportunity to develop themselves
              professionally while using the latest technologies.
            </p>
            <p>
              The business philosophy of our development team is focused on the
              top quality end product. Clean and steady code together with
              strict timeline and planned budget gives superior results each and
              every time.
            </p>
            <div className="_wr-f">
              <div className="_w">
                {cards.map(card => {
                  return (
                    <div className="_l6" key={card.id}>
                      <Card card={card} />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Intro
