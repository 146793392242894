import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import React from "react"
import Seo from "../components/Seo"

const queryLogoData = graphql`
  {
    allLogoSectionJson {
      edges {
        node {
          logo_applications {
            id
            title
            alt
            caption
            image_path {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
              }
            }
          }
          logo_variations {
            id
            title
            alt
            caption
            image_path {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
              }
            }
          }
          erp_pos {
            id
            alt
            image_path {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
              }
            }
          }
          f_webshop {
            id
            alt
            image_path {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
              }
            }
          }
          proper_use {
            id
            alt
            image_path {
              childImageSharp {
                gatsbyImageData(
                  layout: FIXED
                  placeholder: BLURRED
                  transformOptions: { fit: CONTAIN }
                )
              }
            }
          }
        }
      }
    }
  }
`

const LogoSection = () => {
  const logoApplicationData = useStaticQuery(queryLogoData)
  const path = logoApplicationData.allLogoSectionJson.edges[0]

  const logoApplications = path.node.logo_applications
  const logoVariations = path.node.logo_variations
  const erpPos = path.node.erp_pos
  const fWebshop = path.node.f_webshop
  const properUse = path.node.proper_use

  const firstProperUse = properUse.filter((data, index) => {
    return index < 3 ? data : false
  })
  const middleProperUse = properUse.filter((data, index) => {
    return index >= 3 && index <= 4 ? data : false
  })

  const restOfProperUse = properUse.filter((data, index) => {
    return index > 4 ? data : false
  })

  return (
    <div id="logo" className="-sections">
      <main className="m-main">
        <div className="_wr">
          <div className="_w">
            <div className="m-main__content _12">
              <Seo title="Logo" />
              <h1>Logo</h1>
              <p>
                Ewasoft represents the essence of quality software topped off
                with good design.
              </p>
              <p>
                In every situation our logo represents our professional
                accomplishments and everything we stand for as a company.
              </p>
              <p>
                In its primary state, the logo is a black and red wordmark on a
                white background. Given that its a dual color logo, several
                versions were created to help it adapt in various situations.
              </p>
              <p>
                As a rule of thumb however, the logo must be put on a simple,
                single-color background which does not compromise its visibility
                and legibility.
              </p>
              <StaticImage
                layout="constrained"
                src="../images/ewasoft-logo.png"
                objectFit="contain"
                alt="ewasoft logo"
                className="-mt30"
              />
              <section className="m-logoSection a-separator">
                <h2 className="a-heading -large">Clear space</h2>
                <p>
                  Clear space is the area that is required around the outside of
                  the logo. It must be kept free of other graphic elements such
                  as headlines, text, images and the outside edge of materials.
                </p>
                <p>
                  The minimum required clear space for the logo is defined by
                  the measurement “x” . This measurement is equal to the height
                  of the wordmark’s letter e.
                </p>
                <div className="_wr">
                  <div className="_w">
                    <div className="_l6 -flex -aic -mt30">
                      <StaticImage
                        src="../images/e-letter.png"
                        alt="ewasoft e letter"
                      />
                    </div>
                    <div className="_l6 -mt30">
                      <StaticImage
                        src="../images/logo-print.png"
                        alt="ewasoft print logo"
                      />
                    </div>
                  </div>
                </div>
              </section>
              <section className="m-logoSection a-separator">
                <h2 className="a-heading -large">Logo application</h2>
                <p>
                  The logo should, whenever possible, be used on a simple or
                  single color background in the version which gives the biggest
                  contrast. This is meant to ensure the best visibility of the
                  logo.
                </p>
                <div className="_wr">
                  <div className="_w">
                    {logoApplications.map(data => {
                      const { id, caption, title, alt } = data
                      const image = getImage(data.image_path)

                      return (
                        <div className="_xl6 m-logoSection__column" key={id}>
                          <div className="m-logoSection__column--content">
                            <span className="m-logoSection__column--contentCaption">
                              {caption}
                            </span>
                            <p className="m-logoSection__column--contentTitle">
                              {title}
                            </p>
                          </div>
                          <GatsbyImage image={image} alt={alt} />
                        </div>
                      )
                    })}
                  </div>
                </div>
              </section>
              <section className="m-logoSection a-separator -variations">
                <h2 className="a-heading -large">Logo variation</h2>
                <p>
                  Ewasoft’s W can be used as a variation of the full wordmark
                  logo. The lettermark can be used as a standalone logo or a
                  graphic detail.
                </p>
                <p>
                  When used as a graphic detail, the lettermark can be used in
                  any approved ewasoft color or different blending modes.
                </p>
                <div className="_wr">
                  <div className="_w">
                    {logoVariations.map(data => {
                      const { id, caption, title, alt } = data
                      const image = getImage(data.image_path)

                      return (
                        <div className="_xl6 m-logoSection__column" key={id}>
                          <div className="m-logoSection__column--content">
                            <span className="m-logoSection__column--contentCaption">
                              {caption}
                            </span>
                            <p className="m-logoSection__column--contentTitle">
                              {title}
                            </p>
                          </div>
                          <GatsbyImage image={image} alt={alt} />
                        </div>
                      )
                    })}
                  </div>
                </div>
              </section>
              <section className="m-logoSection a-separator">
                <h2 className="a-heading -large">ERP and POS</h2>
                <p>
                  Ewasoft’s brands have their own logos, ones derived from the
                  ewasoft brand. These logos also come with a variation which
                  can be used as an icon or whenever it would fit the design
                  better than a full wordmark.
                </p>
                <p>
                  The colors used in these logos are the Ewa brand colors. Ewa
                  black and Ewa gray are used in web and rich black and print
                  gray for printing purposes.
                </p>
                <div className="_wr">
                  <div className="_w">
                    {erpPos.map(data => {
                      const { id, alt } = data
                      const image = getImage(data.image_path)

                      return (
                        <div className="_xl6 m-logoSection__column" key={id}>
                          <GatsbyImage image={image} alt={alt} />
                        </div>
                      )
                    })}
                  </div>
                </div>
              </section>
              <section className="m-logoSection a-separator -fWebshop">
                <h2 className="a-heading -large">F Webshop</h2>
                <p>
                  F-webshop’s original logo consists of the logomark and
                  wordmark in E webshop Red and Light Gray. When used on a dark
                  background the Gray should be swapped out with white. Make
                  sure to remember that the version of the logo for a red
                  background uses Dark Gray and white instead of Light Gray.
                </p>
                <p>
                  Unlike Ewasoft, F webshop doesnt have a monochrome version.
                </p>
                <div className="_wr">
                  <div className="_w">
                    {fWebshop.map(data => {
                      const { id, alt } = data
                      const image = getImage(data.image_path)

                      return (
                        <div className="_xl4 m-logoSection__column" key={id}>
                          <GatsbyImage image={image} alt={alt} />
                        </div>
                      )
                    })}
                  </div>
                </div>
              </section>
              <section className="m-logoSection a-separator -properUse">
                <h2 className="a-heading -large">Proper use</h2>
                <p>It is important to protect the integrity of the brand.</p>
                <p>
                  This means that the logo must always be used in the proper,
                  approved way. Any modification of the logo that confuses it’s
                  meaning and diminishes it’s impact, is not allowed.
                </p>
                <p>
                  As shown in these examples, the logo must never be skewed or
                  flattened, given a drop shadow, changed to an unapproved color
                  (even from the ewasoft color palette), or put on a background
                  which will impair its legibility.
                </p>
                <p>
                  Never redraw, translate or otherwise alter the logo in any
                  way.
                </p>
                <div className="_wr">
                  <div className="_w">
                    {firstProperUse.map(data => {
                      const { id, alt } = data
                      const image = getImage(data.image_path)

                      return (
                        <div className="_xl4 m-logoSection__column" key={id}>
                          <GatsbyImage image={image} alt={alt} />
                        </div>
                      )
                    })}
                    <div className="_xl4 m-logoSection__column -blank"></div>
                    {middleProperUse.map(data => {
                      const { id, alt } = data
                      const image = getImage(data.image_path)

                      return (
                        <div className="_xl4 m-logoSection__column" key={id}>
                          <GatsbyImage image={image} alt={alt} />
                        </div>
                      )
                    })}
                    <div className="_xl4 m-logoSection__column -blank"></div>
                    {restOfProperUse.map(data => {
                      const { id, alt } = data
                      const image = getImage(data.image_path)

                      return (
                        <div className="_xl4 m-logoSection__column" key={id}>
                          <GatsbyImage image={image} alt={alt} />
                        </div>
                      )
                    })}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default LogoSection
