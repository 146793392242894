import React from "react"
import Seo from "../components/Seo"

const Resources = () => {
  return (
    <div id="resources" className="-sections">
      <main className="m-main">
        <div className="_wr">
          <div className="_w">
            <div className="m-main__content _12 -resources">
              <Seo title="Homepage" />
              <h1>Resources</h1>
              <p>
                This page contains links to some useful resources to help with
                designing all manners of documentation, web or print materials.
              </p>
              <p>
                Feel free to let your creativity out, but use common sense when
                it comes to the topic of the design.
              </p>
              <p>
                When in doubt, don't hesitate to consult with the rest of the
                design team or your manager.
              </p>
              <div className="_w">
                <div className="_2 -tabs">
                  <span>Illustrations</span>
                  <span>Images</span>
                  <span>MockUps</span>
                  <span>Icons</span>
                </div>
                <div className="-links">
                  <div>
                    <span>
                      <a
                        href="https://www.blobmaker.app/"
                        target={"_blank"}
                        rel={"noreferrer"}
                      >
                        BlobMaker
                      </a>{" "}
                    </span>
                    <span className="-left">
                      <a
                        href="https://blush.design/"
                        target={"_blank"}
                        rel={"noreferrer"}
                      >
                        Blush
                      </a>{" "}
                    </span>
                    <span className="-left">
                      <a
                        href="https://undraw.co/"
                        target={"_blank"}
                        rel={"noreferrer"}
                      >
                        Undraw
                      </a>{" "}
                    </span>
                  </div>
                  <div>
                    <span>
                      <a
                        href="https://storyset.com/"
                        target={"_blank"}
                        rel={"noreferrer"}
                      >
                        StorySet
                      </a>{" "}
                    </span>
                    <span className="-left">
                      <a
                        href="https://www.openpeeps.com/"
                        target={"_blank"}
                        rel={"noreferrer"}
                      >
                        OpenPeeps
                      </a>{" "}
                    </span>
                  </div>
                  <div>
                    <span>
                      <a
                        href="https://www.freepik.com/"
                        target={"_blank"}
                        rel={"noreferrer"}
                      >
                        Freepik
                      </a>{" "}
                    </span>
                  </div>
                  <div>
                    <span>
                      <a
                        href="https://www.pexels.com/"
                        target={"_blank"}
                        rel={"noreferrer"}
                      >
                        Pexels
                      </a>{" "}
                    </span>
                    <span className="-left">
                      <a
                        href="https://unsplash.com/"
                        target={"_blank"}
                        rel={"noreferrer"}
                      >
                        Unsplash
                      </a>{" "}
                    </span>
                  </div>
                  <div>
                    <span>
                      <a
                        href="https://elements.envato.com/"
                        target={"_blank"}
                        rel={"noreferrer"}
                      >
                        Envato
                      </a>{" "}
                    </span>
                  </div>
                  <div>
                    <span>
                      <a
                        href="https://feathericons.com/"
                        target={"_blank"}
                        rel={"noreferrer"}
                      >
                        FeatherIcons
                      </a>{" "}
                    </span>
                    <span className="-left">
                      <a
                        href="https://tablericons.com/"
                        target={"_blank"}
                        rel={"noreferrer"}
                      >
                        TablerIcons
                      </a>{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Resources
