import React from "react"
import Layout from "../components/Layout"
import Modal from "../components/Modal"
import Intro from "../components/Intro"
import Seo from "../components/Seo"
import Sections from "../components/Sections"
import { StaticImage } from "gatsby-plugin-image"


const CARD_DATA = [
  {
    id: 1,
    title: "Our Mission",
    text: "Increase of profitability and efficiency of client-related business through development and implementation of software solutions.",
    iconId: "rocket-01",
  },
  {
    id: 2,
    title: "Our Vision",
    text: "To become the synonym for high-quality enterprise web business solutions based on state-of-the-art technologies.",
    iconId: "cube-02",
  },
]

const Home = () => {
  return (
    <>
      <Modal />
      <Layout>
        <Seo title="Homepage" />
        <Intro cards={CARD_DATA} />
      </Layout>
      <Sections />
      <StaticImage
        className="m-container__image"
        src="../images/logo-small.png"
        alt="ewasoft logo small"
      />
    </>
  )
}

export default Home
