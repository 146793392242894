import React from "react"
import Seo from "../components/Seo"

const colorData = {
  first_row: {
    first_column: [
      {
        id: 1,
        title: "EWA RED",
        color_hex: "D32128",
        rgb: "R211 G33 B44",
        cmyk: "C20 M100 Y100 K5",
      },
    ],

    second_column: [
      {
        id: 1,
        color_hex: "D7373E",
        ratio: "90%",
      },
      {
        id: 2,
        color_hex: "DE595E",
        ratio: "75%",
      },
      {
        id: 3,
        color_hex: "E57A7E",
        ratio: "60%",
      },
    ],

    third_column: [
      {
        id: 1,
        color_hex: "F2BCBF",
        ratio: "30%",
      },
      {
        id: 2,
        color_hex: "FBE9E9",
        ratio: "10%",
      },
    ],
  },

  second_row: {
    first_column: [
      {
        id: 1,
        title: "EWA BLACK",
        color_hex: "010D0E",
        rgb: "R1 G13 B14",
        text: "Exclusively for web",
      },
    ],

    second_column: [
      {
        id: 1,
        color_hex: "031D1F",
      },
      {
        id: 2,
        color_hex: "0E3338",
      },
    ],

    third_column: [
      {
        id: 1,
        color_hex: "AFBCBD",
      },
      {
        id: 2,
        color_hex: "DBE6E7",
      },
      {
        id: 3,
        color_hex: "EEF5F5",
      },
    ],
  },

  third_row: {
    first_column: [
      {
        id: 1,
        title: "RICH BLACK",
        color_hex: "000",
        cmyk: "C3 M30 Y30 K100",
        text: "Exclusively for web",
      },
    ],
    second_column: [
      {
        id: 1,
        title: "WHITE",
        color_hex: "FFFFFF",
        rgb: "R255 G255 B255",
        cmyk: "C0 M0 Y0 K0",
      },
    ],
  },
}

const colorSecondary = [
  {
    id: 1,
    color_hex: "FF1B3F",
  },
  {
    id: 2,
    color_hex: "4072EE",
  },
  {
    id: 3,
    color_hex: "B7B3A8",
  },
  {
    id: 4,
    color_hex: "FEC400",
  },
]

const erpPos = {
  first_column: [
    {
      id: 1,
      title: "EWA GRAY",
      color_hex: "AFBCBD",
      rgb: "R175 G188 B189",
      text: "Exclusively for web",
    },
    {
      id: 2,
      title: "WHITE",
      color_hex: "FFFFFF",
      rgb: "R255 G255 B255",
      cmyk: "C0 M0 Y0 K0",
    },
    {
      id: 3,
      title: "PRINT GRAY",
      color_hex: "B8BBBC",
      cmyk: "C35 M25 Y25 K0",
      text: "Exclusively for print",
    },
  ],

  second_column: [
    {
      id: 1,
      title: "EWA BLUE",
      color_hex: "0B4A66",
      rgb: "R11 G74 B102",
      cmyk: "C95 M60 Y40 K30",
    },
    {
      id: 2,
      title: "EWA BLACK",
      color_hex: "010D0E",
      rgb: "R1 G13 B14",
      text: "Exclusively for web",
    },
    {
      id: 3,
      title: "RICH BLACK",
      color_hex: "000",
      cmyk: "C3 M30 Y30 K100",
      text: "Exclusively for print",
    },
  ],
}

const webshop = {
  first_row: [
    {
      id: 1,
      title: "LIGHT GRAY",
      color_hex: "4D4D4E",
      rgb: "R77 G77 B78",
      cmyk: "C0 M0 Y70 K80",
    },
    {
      id: 2,
      title: "DARK GRAY",
      color_hex: "312F30",
      cmyk: "C0 M0 Y0 K95",
    },
  ],

  second_row: [
    {
      id: 1,
      title: "F WEBSHOP RED",
      color_hex: "E74E4D",
      rgb: "R231 G78 B77",
      cmyk: "C3 M85 Y70 K0",
    },
    {
      id: 2,
      title: "F WEBSHOP BLUE",
      subtitle: "secondary",
      color_hex: "69A5D6",
      rgb: "R105 G165 B214",
      cmyk: "C58 M23 Y0 K0",
    },
  ],
}

const Color = () => {
  return (
    <div id="color" className="-sections">
      <main className="m-main">
        <div className="_wr">
          <div className="_w">
            <div className="m-main__content _12">
              <Seo title="Color" />
              <h1>Color</h1>
              <p>
                The company’s primary color is Ewa Red. As seen in the example
                of the company logo, the color should be used sparingly in
                official company documentation and print materials. This rule
                does not apply when it comes to visuals created for the
                company’s social media platforms. All levels of the colors
                opacity are acceptable to use in all cases.
              </p>
              <p>
                The Company’s secondary color is Ewa Black. As gray opacity
                percentages of a black color can sometimes be flat, adding more
                color to the shades is encouraged. In print Ewa Black should be
                switched out for Rich Black.
              </p>
              <h4 className="-mt20">
                Ewasoft’s brand must never use gradients in any design.
              </h4>
              <section className="m-color -primary _wr">
                <div className="_w no-gutters">
                  <div className="_l6 m-color__mainColumn">
                    {colorData.first_row.first_column.map(color => {
                      return (
                        <div
                          className="m-color__content -red -main"
                          style={{ backgroundColor: `#${color.color_hex}` }}
                          key={color.id}
                        >
                          <span className="m-color__content--title">
                            {color.title}
                          </span>
                          <div className="m-color__content--group">
                            <p>{color.color_hex}</p>
                            <p>{color.rgb}</p>
                            <p>{color.cmyk}</p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className="_l3 m-color__mainColumn">
                    <div className="-red -three">
                      {colorData.first_row.second_column.map(color => {
                        const { id, color_hex, ratio } = color

                        return (
                          <div
                            className="m-color__content"
                            style={{ backgroundColor: `#${color_hex}` }}
                            key={id}
                          >
                            <span className="m-color__content--title">
                              {color_hex}
                            </span>
                            <span className="m-color__content--ratio">
                              {ratio}
                            </span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className="_l3 m-color__mainColumn">
                    <div className="-red -two">
                      {colorData.first_row.third_column.map(color => {
                        const { id, color_hex, ratio } = color
                        return (
                          <div
                            className="m-color__content"
                            style={{ backgroundColor: `#${color_hex}` }}
                            key={id}
                          >
                            <span className="m-color__content--title">
                              {color_hex}
                            </span>
                            <span className="m-color__content--ratio">
                              {ratio}
                            </span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className="_w no-gutters">
                  <div className="_l6 m-color__mainColumn">
                    <div className="-black">
                      {colorData.second_row.first_column.map(color => {
                        const { id, color_hex, title, rgb, text } = color
                        return (
                          <div
                            className="m-color__content -black -main"
                            style={{ backgroundColor: `#${color_hex}` }}
                            key={id}
                          >
                            <span className="m-color__content--title">
                              {title}
                            </span>
                            <div className="m-color__content--group">
                              <p>{color_hex}</p>
                              <p>{rgb}</p>
                              <p className="m-color__content--groupText">
                                {text}
                              </p>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className="_l3 m-color__mainColumn">
                    <div className="-black -two">
                      {colorData.second_row.second_column.map(color => {
                        const { id, color_hex, ratio } = color
                        return (
                          <div
                            className="m-color__content"
                            style={{ backgroundColor: `#${color_hex}` }}
                            key={id}
                          >
                            <span className="m-color__content--title">
                              {color_hex}
                            </span>
                            <span className="m-color__content--ratio">
                              {ratio}
                            </span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className="_l3 m-color__mainColumn">
                    <div className="-black -three">
                      {colorData.second_row.third_column.map(color => {
                        const { id, color_hex, ratio } = color
                        return (
                          <div
                            className="m-color__content -richBlack"
                            style={{ backgroundColor: `#${color_hex}` }}
                            key={id}
                          >
                            <span className="m-color__content--title">
                              {color_hex}
                            </span>
                            <span className="m-color__content--ratio">
                              {ratio}
                            </span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div className="_w no-gutters">
                  <div className="_l6 m-color__mainColumn">
                    {colorData.third_row.first_column.map(color => {
                      const { id, color_hex, title, rgb, text } = color
                      return (
                        <div
                          className="m-color__content -lastRow -main"
                          style={{ backgroundColor: `#${color_hex}` }}
                          key={id}
                        >
                          <span className="m-color__content--title">
                            {title}
                          </span>
                          <div className="m-color__content--group">
                            <p>{color_hex}</p>
                            <p>{rgb}</p>
                            <p>{text}</p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className="_l6 m-color__mainColumn">
                    {colorData.third_row.second_column.map(color => {
                      const { id, color_hex, title, cmyk, rgb } = color
                      return (
                        <div
                          className="m-color__content -lastRow -black -main"
                          style={{ backgroundColor: `#${color_hex}` }}
                          key={id}
                        >
                          <span className="m-color__content--title">
                            {title}
                          </span>
                          <div className="m-color__content--group">
                            <p>{color_hex}</p>
                            <p>{rgb}</p>
                            <p>{cmyk}</p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </section>
              <section className="m-section a-separator">
                <div className="m-section__content">
                  <h2 className="a-heading -large">Secondary</h2>
                  <p className="m-section__content--text">
                    Secondary colors are exclusively reserved for Ewasoft’s
                    online media presence.
                  </p>
                  <p className="m-section__content--text">
                    Currently largely underutilized, these color are here to
                    support the brand’s varius future subinterests. They can be
                    used as supporting colors, as a means of color coding
                    different aspects of the brands online presence.
                  </p>
                  <p className="m-section__content--text">
                    For example, one color may be devoted to the brand’s
                    educational posts, announcements etc. In such cases, adding
                    colors, or using different opacity levels of the colors
                    represented here is encouraged if needed to enhance the
                    design of a post.
                  </p>
                  <div className="m-color -secondary _wr">
                    <div className="_w">
                      {colorSecondary.map((color, index) => {
                        return (
                          <div
                            className={`_xl6 m-color__mainColumn ${
                              index >= 2 ? `-secondRow` : ""
                            }`}
                            key={color.id}
                            style={{ backgroundColor: `#${color.color_hex}` }}
                          >
                            {color.color_hex}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </section>
              <section className="m-section a-separator">
                <div className="m-section__content">
                  <h2 className="a-heading -large">ERP and POS colors</h2>
                  <p className="m-section__content--text">
                    The company’s main products, namely its ERP and POS systems
                    are represented by their own logos. Ones born out of the
                    Ewasoft mother logo.
                  </p>
                  <p className="m-section__content--text">
                    Along with said logos, the brands are represented with their
                    own color palette, separate from, but related to the primary
                    Ewasoft colors.
                  </p>
                </div>
              </section>
              <section className="m-color -erp _wr">
                <div className="_w no-gutters">
                  <div className="_xl6 m-color__mainColumn">
                    {erpPos.first_column.map((color, index) => {
                      const { id, color_hex, title, rgb, cmyk, text } = color

                      return (
                        <div
                          className={`m-color__content -main -firstCol ${
                            index === 1 ? "-small" : "-big"
                          }`}
                          style={{ backgroundColor: `#${color_hex}` }}
                          key={id}
                        >
                          <span className="m-color__content--title">
                            {title}
                          </span>
                          <div className="m-color__content--group">
                            <p>{color_hex}</p>
                            <p>{rgb}</p>
                            <p>{cmyk}</p>
                            <p className="-exclusively">{text}</p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className="_xl6 m-color__mainColumn">
                    {erpPos.second_column.map((color, index) => {
                      const { id, color_hex, title, rgb, cmyk, text } = color
                      return (
                        <div
                          className={`m-color__content -main -secondCol ${
                            index === 0 ? "-big" : "-small"
                          }`}
                          style={{ backgroundColor: `#${color_hex}` }}
                          key={id}
                        >
                          <span className="m-color__content--title">
                            {title}
                          </span>
                          <div className="m-color__content--group">
                            <p>{color_hex}</p>
                            <p>{rgb}</p>
                            <p>{cmyk}</p>
                            <p className="-exclusively">{text}</p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </section>
              <section className="m-section a-separator _wr">
                <div className="m-section__content _w -web">
                  <h2 className="a-heading -large">F Webshop colors</h2>
                  <p className="m-section__content--text">
                    Much like ERP and POS, F-webshop has its on logo and color
                    palette, however its more independent from the Ewa brand.
                  </p>
                  <p className="m-section__content--text">
                    The colors are similar, the primary color being red accented
                    by gray tones. Where F-webshop differs is that it also has a
                    secondary blue color used in its documentation to help bring
                    out the primary red more.
                  </p>
                </div>
              </section>
              <section className="m-color -webshop _wr">
                <div className="_w no-gutters">
                  {webshop.first_row.map(color => {
                    const { id, title, color_hex, rgb, cmyk } = color
                    return (
                      <div className="_xl6 m-color__mainColumn" key={id}>
                        <div
                          className="m-color__content -firstRow -main"
                          style={{ backgroundColor: `#${color_hex}` }}
                        >
                          <span className="m-color__content--title">
                            {title}
                          </span>
                          <div className="m-color__content--group">
                            <p>{color_hex}</p>
                            <p>{rgb}</p>
                            <p>{cmyk}</p>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="_w no-gutters">
                  {webshop.second_row.map((color, index) => {
                    const { id, title, color_hex, rgb, cmyk } = color
                    return (
                      <div
                        className={`${
                          index === 0 ? "_xl8" : "_xl4"
                        } m-color__mainColumn`}
                        key={id}
                      >
                        <div
                          className="m-color__content -secondRow -main"
                          style={{ backgroundColor: `#${color_hex}` }}
                        >
                          <span className="m-color__content--title">
                            {title}
                          </span>
                          <div className="m-color__content--group">
                            <p>{color_hex}</p>
                            <p>{rgb}</p>
                            <p>{cmyk}</p>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Color
