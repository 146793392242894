import React from "react"

const Icon = ({ id, className, ...props }) => {
  return (
    <>
      <svg className={`a-icons ${className}`} {...props}>
        <use href={`/sprite.svg#${id}`} />
      </svg>
    </>
  )
}

export default Icon
