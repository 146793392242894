import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Seo from "../components/Seo"
import Icon from "../components/Icon"

const outlinedIcons = [
  {
    id: 1,
    iconId: "e-commerce-icon",
  },
  {
    id: 2,
    iconId: "target",
  },
  {
    id: 3,
    iconId: "industrials-icon",
  },
  {
    id: 4,
    iconId: "group",
  },
  {
    id: 5,
    iconId: "marketing-icon",
  },
  {
    id: 6,
    iconId: "bulb",
  },
  {
    id: 7,
    iconId: "real-estate-icon",
  },
  {
    id: 8,
    iconId: "arrow-cube",
  },
]

const duotoneIcons = [
  {
    id: 1,
    iconId: "processor",
  },
  {
    id: 2,
    iconId: "joystick",
  },
  {
    id: 3,
    iconId: "diamond",
  },
  {
    id: 4,
    iconId: "nft",
  },
  {
    id: 5,
    iconId: "version",
  },
  {
    id: 6,
    iconId: "rocket",
  },
]

const Imagery = () => {
  return (
    <div id="imagery" className="-sections">
      <main className="m-main">
        <div className="_wr">
          <div className="_w">
            <div className="m-main__content _12">
              <Seo title="Imagery" />
              <h1>Imagery</h1>
              <p>
                The key is to keep with the minimalistic style of the brand.
                Ewasofts brand is open to different types of imagery. You’re
                free to use images, illustrations, 3D renders and graphics.
                There are, however, best practices regarding when to use which
                type of imagery.
              </p>
              <StaticImage
                src="../images/ewasoft-3d-logo.png"
                layout="constrained"
                objectFit="contain"
                alt="ewasoft 3D logo"
                className="-mt30"
              />
              <section className="m-section a-separator">
                <div className="m-section__content">
                  <h2 className="a-heading -large">Photography</h2>
                  <p className="m-section__content--text">
                    Ewa’s favored photography type is work and lifestyle
                    photography. We strive to present a day in the life of
                    Ewasoft. To show our culture and values. The photos should
                    feel real and candid.
                  </p>
                  <p className="m-section__content--text">
                    When using portraits, images of our employees should be
                    used. All images should be clean and high quality.
                  </p>
                </div>
              </section>
              <section className="m-layout -photography _wr">
                <div className="_w">
                  <div className="_xl7 m-layout__column">
                    <StaticImage
                      src="../images/andrew-neel.jpg"
                      layout="constrained"
                      objectFit="cover"
                      objectPosition="50% 50%"
                      className="m-layout__column--mainImage"
                      alt="women drinking coffee"
                    />
                  </div>
                  <div className="_xl5 m-layout__column no-gutters -secondColTwo">
                    <div className="_xl6 m-layout__column--subColumn">
                      <StaticImage
                        src="../images/businessman-advisor-two-business-people-talking.jpg"
                        layout="constrained"
                        className="m-layout__column--subColumnImage"
                        objectFit="cover"
                        alt="businessman advisor two business people talking"
                      />
                    </div>
                    <div className="_xl6 m-layout__column--subColumn">
                      <StaticImage
                        src="../images/guy-explaining-topic.jpg"
                        layout="constrained"
                        className="m-layout__column--subColumnImage"
                        objectFit="cover"
                        alt="guy explaining topic"
                      />
                    </div>
                  </div>
                </div>
              </section>
              <section className="m-section a-separator">
                <div className="m-section__content">
                  <h2 className="a-heading -large">Illustration</h2>
                  <p className="m-section__content--text">
                    Illustrations are most commonly used in job postings. No
                    style of illustration is specified as such, but depiction of
                    people in a work environment is what should shown.
                    Non-work-related illustrations can be used in social media.
                  </p>
                  <p className="m-section__content--text">
                    Familiar free illustration sources are mentioned on the
                    resource page.
                  </p>
                  <StaticImage
                    src="../images/illustration-bulb.png"
                    layout="constrained"
                    objectFit="contain"
                    alt="illustration bulb"
                    className="-mt30"
                  />
                </div>
              </section>
              <section className="m-section a-separator">
                <div className="m-section__content">
                  <h2 className="a-heading -large">3D Renders</h2>
                  <p className="m-section__content--text">
                    3D have so far only been used as artwork for Ewa’s website.
                  </p>
                  <p className="m-section__content--text">
                    These renders have a simple and minimalist design, using
                    Ewasoft’s color palette to depict all our technical ventures
                    and products.
                  </p>
                </div>
              </section>
              <section className="m-layout _wr">
                <div className="_w">
                  <div className="_xl7 m-layout__column">
                    <StaticImage
                      src="../images/3d-render.png"
                      layout="constrained"
                      objectFit="cover"
                      alt="3d render"
                    />
                  </div>
                  <div className="_xl5 m-layout__column no-gutters -secondColTwo">
                    <div className="_xl6 m-layout__column--subColumn">
                      <StaticImage
                        src="../images/3d-render-laptop.png"
                        layout="constrained"
                        objectFit="cover"
                        alt="3d render laptop"
                      />
                    </div>
                    <div className="_xl6 m-layout__column--subColumn">
                      <StaticImage
                        src="../images/3d-render-cube.png"
                        layout="constrained"
                        objectFit="cover"
                        alt="3d render cube"
                      />
                    </div>
                  </div>
                </div>
              </section>
              <section className="m-section a-separator">
                <div className="m-section__content">
                  <h2 className="a-heading -large">Graphic elements</h2>
                  <p className="m-section__content--text">
                    Graphic elements that are most often used in Ewa’s social
                    media visuals are most commonly simple line illustrations.
                    They follow the style of the icons used by the Ewasoft
                    website. Line stroke thickness should be paid close
                    attention to.
                  </p>
                  <p className="m-section__content--text">
                    In an instagram post which is 1080 by 1080 px, the stroke of
                    the graphic should be 7pt. this size should be scaled with
                    different style graphics.
                  </p>
                  <StaticImage
                    src="../images/graphic-elements.png"
                    layout="constrained"
                    objectFit="contain"
                    alt="graphic elements"
                    className="-mt30"
                  />
                </div>
              </section>
              <section className="m-section a-separator">
                <div className="m-section__content">
                  <h2 className="a-heading -large">Icons</h2>
                  <p className="m-section__content--text">
                    Ewasoft uses simple, minimalistic outline icons. The stroke
                    should be thin, using Ewa Black as a default state. The
                    active state may either be red or remain black with a
                    dropshadow acting as a highlighter for the icon.
                  </p>
                  <p className="m-section__content--text">
                    Icons may also come in Ewa Red or white on different
                    backgrounds.
                  </p>
                </div>
              </section>
              <section className="m-layout -icons _wr">
                <div className="_w">
                  <div className="_xl4">
                    <div className="m-layout__content">
                      <h4 className="-mt25 -uppercase">Outlined</h4>
                    </div>
                    <div className="m-layout__icons _w -outlined">
                      {outlinedIcons.map(icon => {
                        return (
                          <div
                            className="m-layout__icons--column _xl3 _m6"
                            key={icon.id}
                          >
                            <Icon id={icon.iconId} className="-outlined" />
                          </div>
                        )
                      })}
                    </div>
                    <div className="m-layout__content -mt50">
                      <h4 className="-mt25 -uppercase">Duotone</h4>
                    </div>
                    <div className="m-layout__icons _w -duotone">
                      {duotoneIcons.map(icon => {
                        return (
                          <div
                            className="m-layout__icons--column _xl4 _m6"
                            key={icon.id}
                          >
                            <Icon id={icon.iconId} className="-duotone" />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className="_xl8">
                    <StaticImage
                      src="../images/marketing.png"
                      layout="constrained"
                      objectFit="contain"
                      alt="marketing"
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Imagery
