import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"

const query = graphql`
  {
    site {
      siteMetadata {
        siteDescription: description
        siteTitle: title
      }
    }
  }
`

const Seo = ({ title, description }) => {
  const { site } = useStaticQuery(query)

  const { siteTitle, siteDescription } = site.siteMetadata

  return (
    <Helmet title={`${title} | ${siteTitle}`}>
      <meta name="description" content={description || siteDescription}></meta>
      <meta name="icon" href="/static/favicon.ico" />
    </Helmet>
  )
}

export default Seo
