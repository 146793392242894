import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import Tone from "./Tone"
import Typography from "./Typography"
import Color from "./Color"
import LogoSection from "./Logo"
import Imagery from "./Imagery"
import CommunicationSection from "./Communication"
import Resources from "./Resources"

const Sections = () => {
  const [scrolledIndex, setScrolledIndex] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const sections = Array.from(document.querySelectorAll(".-sections"))

      if (typeof sections !== "undefined") {
        sections.forEach((section, index) => {
          const distanceToTop = section.getBoundingClientRect().top
          const distanceToBottom = section.getBoundingClientRect().bottom

          const isScrolledToView = distanceToTop > 100 || distanceToBottom < -50

          if (!isScrolledToView) {
            setScrolledIndex(index)
          }
        })
      }
    }

    if (typeof document !== "undefined") {
      document.addEventListener("scroll", handleScroll, { passive: true })
    }

    return () => {
      if (typeof document !== "undefined") {
        document.removeEventListener("scroll", handleScroll)
      }
    }
  })

  useEffect(() => {
    const links = Array.from(document.querySelectorAll(".js-navLinks"))
    if (typeof links !== "undefined") {
      links.map((link, index) => {
        if (index === scrolledIndex) {
          return link.classList.add("-active")
        } else {
          return link.classList.remove("-active")
        }
      })
    }
  }, [scrolledIndex])

  return (
    <div>
      <Tone />
      <Typography />
      <Color />
      <LogoSection />
      <Imagery />
      <CommunicationSection />
      <Resources />
    </div>
  )
}

export default Sections
